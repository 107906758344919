import React, {useState, useEffect} from 'react';
import './styles.css';
import Api from '../../../Api';
import DataTable from 'react-data-table-component';
import {Row, Col, Button, Card, CardBody, Container} from 'reactstrap';
import moment from 'moment';
import Swal from 'sweetalert2';

//funcao com hooks
export default function Inadimplentes (props) {

//propriedades
    const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-01'));
    const [dataFinal,  setDataFinal]    = useState(moment().format('YYYY-MM-DD'));
    const [dados, setDados]             = useState([]);
    const [valorTotal, setValorTotal]   = useState('R$ 0,00');
    const [previsaoFaturamentoAnterior, setprevisaoFaturamentoAnterior] = useState(null);
    const [previsaoFaturamentoAtual, setprevisaoFaturamentoAtual]       = useState(null);    
    const [mes, setMes] = useState(null);

//funcoes
function funcAlimentaCard (e) {
    if (typeof e !== 'undefined') {
        e.preventDefault()
    }
    Api.get('/reports/totalInadimplentes', {dataInicial: dataInicial, dataFinal: dataFinal})
    .then(result => {
        setValorTotal(result.data[0].Total_Inadimplentes)
    })
}
function funcAlimentaTabela(e){    
    if (typeof e !== 'undefined'){
      e.preventDefault()
      funcAlimentaCard(e)
    }
    if (moment(dataInicial).isAfter(dataFinal,'day')) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Data Inicial não pode ser maior que a Data Final!',
        type: 'warning',
        confirmButtonText: 'Entendi'
      })
      return;
    }
    Api.get('/reports/inadimplentes', {params:{dataInicial: dataInicial, dataFinal: dataFinal}})
      .then(result=>{
        setDados(result.data)
        })      
      }

useEffect(()=>{
    window.$('.datepicker').datepicker({
        format:      'dd/mm/yyyy',
        language:    'pt-BR',
        autoclose:   true,
    })
    .on('changeDate',(e)=>{
    switch (e.currentTarget.id) {
        case 'dataInicial':
            setDataInicial(moment(e.date).format('YYYY-MM-DD'))
        break;
        case 'dataFinal':
            setDataFinal(moment(e.date).format('YYYY-MM-DD'))
        break;
        default:
        break;
    }              
})
    funcAlimentaTabela()
    funcAlimentaCard()    
    Api.get('/dashboard/comparatives/salesforecast',{params:{mes: mes}})
    .then(result=>{
        setprevisaoFaturamentoAnterior(result.data.Total_Faturamento_Previsto_Anterior)
        setprevisaoFaturamentoAtual(result.data.Total_Faturamento_Previsto_Atual)
    })      
},[]);      

const columns=[
    {
        name:     'Cliente',
        selector: 'Cliente',
        sortable: true
    },
    {
        name:     'Valor',
        selector: 'Valor',
        sortable: true,
        right:    true
    }
]      

return (
    <div className="animated fadeIn">
        <form id="inadimplentes" onSubmit={(e) => funcAlimentaTabela(e)}>      
        <Row className="linha">        
            <Col>
            <h4 id="titulo-inadimplentes">Inadimplentes</h4>
            </Col> 
        </Row>
        <Container className="container-fluid">
            <Row id="datepicker">
            <Col id="col-data-inicial">
                <input id="dataInicial" defaultValue={moment().format('01/MM/YYYY')} type="text" className="form-control datepicker" />  
            </Col>
            <Col id="col-a">
                <h6>à</h6>
            </Col>
            <Col id="col-data-final">
                <input id="dataFinal" defaultValue={moment().format('DD/MM/YYYY')} type="text" className="form-control datepicker" />  
            </Col>
            <Col>
                <Button type="submit" id="botao-buscar" color="info" className="rounded">Buscar</Button>
            </Col>
            </Row>
            <Row id="row-card-total-inadimplentes" className="linha">        
                <Card id="card-total-inadimplentes"className="mr-4 bg-flat-color-1">
                <CardBody>
                    <div className="stat-widget-one">
                        <div className="stat-icon dib"><i className={`ti-money text-success border-success`}></i></div>
                        <div className="stat-content dib">
                            <div className="stat-text">Total a Receber</div>
                            <div className="stat-digit">{valorTotal}</div>
                        </div>
                    </div>
                </CardBody>               
                </Card>            
                <Card id="card-total-inadimplentes"className="mr-4 bg-flat-color-1">
                <CardBody>
                    <div className="stat-widget-one">
                        <div className="stat-icon dib"><i className={`ti-money text-success border-success`}></i></div>
                        <div className="stat-content dib">
                            <div className="stat-text">Total Inadimplencia</div>
                            <div className="stat-digit">{valorTotal}</div>
                        </div>
                    </div>
                </CardBody>               
                </Card>                      
            </Row>            
        </Container>
            <Row id="table-inadimplentes">
                <Col>
                    <DataTable 
                    columns={columns} 
                    data={dados}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    noDataComponent='Nenhum registro encontrado...'
                    pagination={true}
                    compact={true}
                    />
                </Col>
            </Row>
        </form>  
    </div>    
)





}