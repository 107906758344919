import React, {useState, useEffect} from 'react';
import Api from '../../../Api';
import DataTable from 'react-data-table-component';
import {Row, Col, Button, Card, CardBody, Container} from 'reactstrap';
import './styles.css'
import moment from 'moment';
import Swal from 'sweetalert2';

export default function Implatacoes(props){    
    const [dataInicial, setdataInicial] = useState(moment().format('YYYY-MM-01'));
    const [dataFinal,  setDataFinal]    = useState(moment().format('YYYY-MM-DD'));
    const [dados, setDados]             = useState([]);
    const [valorTotal, setValorTotal]   = useState('$0,00');
    
  function funcAlimentaCard(e){
    if (typeof e !== 'undefined') {
      e.preventDefault()
    }
    Api.get('/reports/totalDeployments', {params:{dataInicial: dataInicial, dataFinal: dataFinal}})
      .then(result=>{
        setValorTotal(result.data[0].Total_Implantacao)
      })
  }

  function funcAlimentaTabela(e){
    if (typeof e !== 'undefined') {
      e.preventDefault()
      funcAlimentaCard(e)
    }
    if (moment(dataInicial).isAfter(dataFinal,'day')) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Data Inicial não pode ser maior que a Data Final!',
        type: 'warning',
        confirmButtonText: 'Entendi'
      })
      return;
    }    
    Api.get('/reports/deployments', {params:{dataInicial: dataInicial, dataFinal: dataFinal}})
      .then(result=>{
        setDados(result.data)
        })
      }
    useEffect(()=>{      
      window.$('.datepicker').datepicker({
        format:      'dd/mm/yyyy',
        language:    'pt-BR',
        autoclose:   true,
    })
    .on('changeDate',(e)=>{
      switch (e.currentTarget.id) {
        case 'dataInicial':                
            setdataInicial(moment(e.date).format('YYYY-MM-DD'))      
          break;
          case 'dataFinal':
            setDataFinal(moment(e.date).format('YYYY-MM-DD'))            
          break;
        default:
          break;
      }              
    })
    funcAlimentaTabela()
    funcAlimentaCard()
    },[]);
    
    const columns=[
      {
        name:     'Cliente',
        selector: 'Cliente',
        sortable: true
      },
      {
        name:     'Implantação',
        selector: 'Implantação',
        sortable: true,
        right:    true
      }
    ]
    return (
      <div className="animated fadeIn">
      <form id="implantacoes" onSubmit={(e) => funcAlimentaTabela(e)}>
      <Row className="linha"> 
        <Col>
          <h4 id="titulo-implantacoes">Implantações</h4>
        </Col>        
      </Row>
      <Container>
      <Row id="datepicker">
        <Col id="col-data-inicial">
          <input id="dataInicial" defaultValue={moment().format('01/MM/YYYY')} type="text" className="form-control datepicker" />  
        </Col>
        <Col id="col-a">
          <h6>à</h6>
        </Col>
        <Col id="col-data-final">
          <input id="dataFinal" defaultValue={moment().format('DD/MM/YYYY')} type="text" className="form-control datepicker" />  
        </Col>
        <Col>
            <Button type="submit" id="botao-buscar" color="info" className="rounded">Buscar</Button>
        </Col>
        </Row>
        <Row id="row-card-total-implantacoes" className="linha">
        <Col>
          <Card id="card-total-implantacoes"className=" bg-flat-color-1">
            <CardBody>
              <div className="stat-widget-one">
                  <div className="stat-icon dib"><i className={`ti-money text-success border-success`}></i></div>
                  <div className="stat-content dib">
                      <div className="stat-text">Valor Total</div>
                      <div className="stat-digit">{valorTotal}</div>
                  </div>
              </div>
            </CardBody>               
          </Card>
        </Col>
      </Row>
      </Container>        
        <Row id="table-implantacoes">
          <Col>
              <DataTable 
              columns={columns} 
              data={dados}
              responsive={true}
              striped={true}
              highlightOnHover={true}
              noDataComponent='Nenhum registro encontrado...'
              pagination/>
            </Col>
        </Row>      
      </form>  
      </div>
    )
  }