import React, {Component} from 'react';
import Api from '../../Api';
import {Button,        
        Container,
        } from 'reactstrap';
import './styles.css';
import Cookies from 'js-cookie';


export default class Login extends Component {
    state={
        erro: null
    }
    constructor(props){
        super(props)
        this.textInputNome = React.createRef();
        this.textInputSenha = React.createRef();
    }

    validaLogin= (e) =>{
        e.preventDefault()
        let nome = this.textInputNome.current.value

        let senha = this.textInputSenha.current.value    
        Api.post('/login', {nome, senha})            
            .then(response =>{
                const token = response.data.token
                Cookies.set('token',token)
                this.props.setarLogin(true, nome)
            })
            .catch(err =>{
                const {data, status} = err.response
                if (status === 401){
                    this.setState({erro: data.message})
                }
            })
    }    
  render() {
    return (
        <Container id="login" className="Login">
            <div className="login-content Absolute-Center is-Responsive rounded">
                <div className="login-form rounded">
                    <h3 className="text-center mb-4">Rhaimes</h3>
                    <form onSubmit={this.validaLogin}>
                        <div className="form-group">                            
                            <input id="id-usuario" ref={this.textInputNome} type="text" className="form-control" placeholder="Usuário" />
                        </div>
                            <div className="form-group">                                
                                <input id="id-senha" ref={this.textInputSenha} type="password" className="form-control" placeholder="Senha" />
                            </div>
                        <Button id="id-botao-login" type="submit" color="primary">Login</Button>       
                        {this.state.erro !== null && (<h6 className="text-center">{this.state.erro}</h6>)}
                    </form>
                </div>
            </div>
        </Container>
    );
  }
}