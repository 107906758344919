/* eslint-disable no-const-assign */
import React, { useEffect, useState } from 'react';
import Api from '../../Api';
import { Col, Card, Row, CardBody} from 'reactstrap';
import './styles.css';
import moment from 'moment'

export default function Cards(props){

    const [clientesAtivos, setclientesAtivos]           = useState(null);
    const [valorReceberMes, setvalorReceberMes]         = useState(null);
    const [ticketMedio, setticketMedio]                 = useState(null);
    const [valorClientesNovos, setvalorClientesNovos]   = useState(null);

    useEffect(() => {
        Api.get('/clients/totalactives')
        .then( ({data}) => {    
            setclientesAtivos(data.clientes_ativos)  
        })                
    }, [])

    useEffect(() => {            
        totals(props.data)
    },[props.data])    
    
    function totals(data) {
        let dataInicial = moment(data).format('YYYY-MM-01');
        let dataFinal = moment(data).endOf('month').format('YYYY-MM-DD');            
        Api.get('/dashboard/totals', {params:{dataInicial: dataInicial, dataFinal: dataFinal}})        
        .then(result =>{            
            setvalorReceberMes(result.data.Total_Faturamento_Previsto_Geral);
            setticketMedio(result.data.Media_Faturamento_por_Cliente_Geral);
            setvalorClientesNovos(result.data.Total_Faturamento_Novos_Clientes);
        })
    }

    return (
        <Row id="cards" className="m-0">
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Card id="Card1"className="text-white bg-flat-color-1">
                    <CardBody>
                        <div className="stat-widget-one">
                            <div className="stat-icon dib"><i className={`ti-user text-white border-white`}></i></div>
                            <div className="stat-content dib">
                                <div className="stat-text text-white">Total de Clientes</div>
                                <div className="stat-digit text-white">{clientesAtivos}</div>
                            </div>
                        </div>
                    </CardBody>               
                    </Card>
                </Col>        
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Card id="Card2"className="text-white bg-flat-color-1">
                    <CardBody>
                        <div className="stat-widget-one">
                            <div className="stat-icon dib"><i className="ti-money text-white border-white"></i></div>
                            <div className="stat-content dib">
                                <div className="stat-text text-white">A Receber no Mes</div>
                                <div className="stat-digit text-white">{valorReceberMes}</div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                </Col>     
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Card id="Card3"className="text-white bg-flat-color-1">
                    <CardBody>
                        <div className="stat-widget-one">
                            <div className="stat-icon dib"><i className="ti-ticket text-white border-white text-left"></i></div>
                            <div className="stat-content dib">
                                <div className="stat-text text-white">Ticket Medio por Clientes</div>
                                <div className="stat-digit text-white">{ticketMedio}</div>
                            </div>
                        </div>
                    </CardBody>                  
                    </Card>
                </Col>     
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Card id="Card4"className="text-white bg-flat-color-1">
                        <CardBody>
                            <div className="stat-widget-one">
                                <div className="stat-icon dib"><i className="ti-money text-white border-white"></i></div>
                                <div className="stat-content dib">
                                    <div className="stat-text text-white">Recebido de Clientes Novos</div>
                                    <div className="stat-digit text-white">{valorClientesNovos}</div>
                                </div>
                            </div>
                        </CardBody>                
                    </Card>
                </Col>     
            </Row>   
    )
}
