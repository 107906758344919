import React from 'react';
import {Link} from "react-router-dom";
import {Button, NavbarBrand} from 'reactstrap';
import './styles.css';

const Aside = () =>(
<aside id="left-panel" className="left-panel text-white">
        <nav className="navbar navbar-expand-sm navbar-default">
            <div className="navbar-header">
                <Button className="navbar-toggler" data-toggle="collapse" data-target="#main-menu" aria-controls="main-menu" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fa fa-bars"></i>
                </Button>                
                <NavbarBrand>Rhaimes</NavbarBrand>
            </div>

            <div id="main-menu" className="main-menu collapse navbar-collapse">
                <ul className="nav navbar-nav">
                    <li className="active">
                        <Link to="/"> <i className="menu-icon fa fa-dashboard"></i>Dashboard</Link>
                    </li>
                    <h3 className="menu-title">Relatórios</h3>                                            
                    <li className="active">
                        <Link to="/relatorios/recebimentos"> <i className="menu-icon fa fa-money"></i>Recebimentos</Link>
                    </li>
                    <li className="active">
                        <Link to="/relatorios/implantacoes"> <i className="menu-icon fa fa-users"></i>Implantações</Link>
                    </li>
                    <li className="active">
                        <Link to="/relatorios/mensalidades"> <i className="menu-icon fa fa-calendar-o"></i>Mensalidades</Link>
                    </li>                                                                                   
                    <li className="active">
                        <Link to="/relatorios/inadimplentes"> <i className="menu-icon fa fa-calendar-o"></i>inadimplentes</Link>
                    </li>                    
                </ul>
            </div>        
        </nav>
    </aside>
);

export default(Aside);