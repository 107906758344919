import React, {useEffect, useState} from 'react'
import Api from '../../Api';
import { Col, Row, CardTitle} from 'reactstrap';
import './styles.css';

export default function Comparatives(props){
    const [clientesImplantadosAnterior, setclientesImplantadosAnterior] = useState(null);
    const [clientesImplantadosAtual, setclientesImplantadosAtual]       = useState(null);
    const [previsaoFaturamentoAnterior, setprevisaoFaturamentoAnterior] = useState(null);
    const [previsaoFaturamentoAtual, setprevisaoFaturamentoAtual]       = useState(null);
    const [recebimentosAnterior, setrecebimentosAnterior]               = useState(null);
    const [recebimentosAtual, setrecebimentosAtual]                     = useState(null);

    function consultaApi(pMes){
        Api.get('/dashboard/comparatives/newclients', {params:{mes: pMes}})
        .then(result=>{            
            setclientesImplantadosAnterior(result.data.Implantacoes_Mes_Anterior)
            setclientesImplantadosAtual(result.data.Implantacoes_Mes_Atual)
        })
        Api.get('/dashboard/comparatives/salesforecast',{params:{mes: pMes}})
        .then(result=>{
            setprevisaoFaturamentoAnterior(result.data.Total_Faturamento_Previsto_Anterior)
            setprevisaoFaturamentoAtual(result.data.Total_Faturamento_Previsto_Atual)
        })
        Api.get('/dashboard/comparatives/receipts',({params:{mes: pMes}}))
        .then(result=>{            
            setrecebimentosAnterior(result.data.Total_Recebimentos_Anterior)
            setrecebimentosAtual(result.data.Total_Recebimentos_Atual)
        })        
    }

    useEffect(() =>{
        consultaApi(props.data)
    },[])

    useEffect(() =>{        
        consultaApi(props.data)
    },[props.data])
    return (
        <Row id="comparatives">
            <Col xs="12" sm="12" md="12" lg="4">
                <div id="comparative1" className="card no-padding mb-3 rounded">
                    <div className="card-body text-center">
                        <Row>
                            <Col className="align-self-center" sm="12" md="8">
                                <CardTitle className="text-left">
                                    <h4>Clientes Implantados</h4>
                                </CardTitle>                        
                            </Col>
                            <Col md="4">
                                <div className="h1 text-muted text-right mb-4">
                                    <i className="fa fa-users"></i>
                                </div>                        
                            </Col>
                        </Row>                                                
                        <Row>
                            <Col>
                                <div className="h4 mb-6">
                                    <span className="count">{clientesImplantadosAnterior}</span>
                                    <br/>
                                    <span className="description">Mês Anterior</span>
                                </div>                                
                            </Col>
                            <Col>
                                <div className="h4 mb-6">
                                    <span className="count">{clientesImplantadosAtual}</span>
                                    <br/>
                                    <span className="description">Mês Atual</span>
                                </div>
                            </Col>
                        </Row>
                        <div className="progress progress-xs mt-3 mb-0 bg-flat-color-1"></div>                    

                    </div>
                </div>   
            </Col>
            <Col xs="12" sm="12" md="12" lg="4">
                <div id="comparative2" className="card no-padding mb-3 rounded">
                    <div className="card-body text-center">
                        <Row>
                            <Col className="align-self-center" sm="12" md="8">
                                <CardTitle className="text-left">
                                <h4>Previsao Faturamento</h4>
                                </CardTitle>                        
                            </Col>
                            <Col md="4">
                                <div className="h1 text-muted text-right mb-4">
                                    <i className="fa fa-bar-chart-o"></i>
                                </div>                        
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="h4 mb-6">
                                    <span className="count">{previsaoFaturamentoAnterior}</span>
                                    <br/>
                                    <span className="description">Mês Anterior</span>
                                </div>
                            </Col>
                            <Col>
                                <div className="h4 mb-6">
                                    <span className="count">{previsaoFaturamentoAtual}</span>
                                    <br/>
                                    <span className="description">Mês Atual</span>
                                </div>
                            </Col>
                        </Row>
                        <div className="progress progress-xs mt-3 mb-0 bg-flat-color-1"></div>                    
                    </div>
                </div>                 
            </Col>  
            <Col xs="12" sm="12" md="12" lg="4">
                <div id="comparative3" className="card no-padding mb-3 rounded">
                    <div className="card-body text-center">
                        <Row>
                            <Col className="align-self-center" sm="12" md="8">
                                <CardTitle className="text-left">
                                    <h4>Recebimentos</h4>
                                </CardTitle>                        
                            </Col>
                            <Col md="4">
                                <div className="h1 text-muted text-right mb-4">
                                    <i className="fa fa-money"></i>
                                </div>                        
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="h4 mb-6">
                                    <span className="count">{recebimentosAnterior}</span>
                                    <br/>
                                    <span className="description">Mês Anterior</span>
                                </div>
                            </Col>
                            <Col>
                                <div className="h4 mb-6">
                                    <span className="count">{recebimentosAtual}</span>
                                    <br/>
                                    <span className="description">Mês Atual</span>
                                </div>
                            </Col>
                        </Row>
                        <div className="progress progress-xs mt-3 mb-0 bg-flat-color-1"></div>                    
                    </div>
                </div>             
            </Col>           
        </Row>
        );    

}
