import React, { Component } from 'react';
import {Col,Row,Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label} from 'reactstrap';
import { Link } from 'react-router-dom'
import './styles.css';
import Api from '../../Api';

export default class Header extends Component{
    state = {
        modal: false,
        tipoDocumento: [],      
        isChecked: new Map()
      };
sair=()=>{
    this.props.setarLogin(false)
}

toggle = () => {
    this.setState(prevState => ({
        modal: !prevState.modal
    }))
}

atualizaTiposDocumento = () => {
    Api.get(`/financeiro/tiposDeDocumento`)
    .then(result => {
        this.setState({tipoDocumento: result.data})    
    })
}

handleCheck = (e) => {
    this.setState({
        tipoDocumento: this.state.tipoDocumento.map(item => {
            if (parseInt(e.target.id) === item.ID_Tipo) {
                item.Exibir_App = !item.Exibir_App;
            }
            return item;
        })
    })

    Api.put(`/financeiro/updateDocumento`, {params:{idTipo: e.target.id, parametro: e.target.checked}}) 
    .then({});
    
}

async componentDidMount () {
    const result = await Api.get(`/financeiro/tiposDeDocumento`)
    this.setState({tipoDocumento: result.data})
}
    render(){
        return(
            <>
            <header id="header" className="header">                   
            <Row>
                <Col id="items">
                    {/* <span>{this.props.usuario}</span>
                    <i className="ml-1 mr-5 ti-user"></i>*/}
                    <i className="mx-3 ti-settings" onClick={this.toggle}></i>                    
                    <Link to="/" className="mr-4 ti-power-off" onClick={this.sair}></Link>                                                                                               
                </Col>
            </Row>                            
            </header>
            <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Configurações</ModalHeader>
            <ModalBody>
                <h5 className="mb-1">Documentos filtrados:</h5>
                <section id="tiposDocumento">
                    {this.state.tipoDocumento.map(tipos => (
                        <article key={tipos.ID_Tipo}>
                        <FormGroup check>
                            <Label className="custom-control custom-checkbox custom-control-inline" id="labelCheckbox">
                                <Input type="checkbox"
                                    id={tipos.ID_Tipo}
                                    checked={tipos.Exibir_App}
                                    onChange={this.handleCheck}
                                />
                                {tipos.Descricao}
                            </Label>                
                        </FormGroup>
                    </article>
                    ))}
                </section>
            </ModalBody>
            <ModalFooter>
                {/* <Button color="primary" onClick={this.toggle}>Salvar</Button>
                <Button color="secondary" onClick={this.toggle}>Cancelar</Button> */}
            </ModalFooter>
            </Modal>
            </>                        
        )
    }
};
