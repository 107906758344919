import React, {useState, useEffect} from 'react';
import Api from '../../../Api';
import DataTable from 'react-data-table-component';
import {Row, Col, Card, CardBody} from 'reactstrap';
import './styles.css'

export default function Mensalidades(props){
  const [dados, setDados] = useState([]);
  const [valorTotal, setValorTotal]   = useState('R$ 0,00');

  function funcAlimentaCard(e){
      if (typeof e !== 'undefined'){
        e.preventDefault()
      }
      Api.get('/reports/totalMonthlyPayments')
      .then(result=>{          
          setValorTotal(result.data[0].Total_Mensalidade)
        })
    }
  function funcAlimentaTabela(e){
    if (typeof e !== 'undefined') {
      e.preventDefault()
    }    
    Api.get('/reports/monthlyPayments')
      .then(result=>{
        setDados(result.data)
        })
      }
    useEffect(()=>{
      funcAlimentaCard()
      funcAlimentaTabela()
    },[]);
    
    const columns=[
      {
        name:     'Cliente',
        selector: 'Cliente',
        sortable: true
      },
      {
        name:     'Mensalidade',
        selector: 'Mensalidade',
        sortable: true,
        right:    true
      }
    ]
    return (   
      <div className="animated fadeIn">
      <form id="mensalidades" onSubmit={(e) => funcAlimentaTabela(e)}>
      <Row className="titulo"> 
        <h4 id="titulo-mensalidades">Mensalidades</h4>
      </Row> 
      <Row id="row-card-total-mensalidades" className="linha">
        <Col>
          <Card id="card-total-mensalidades"className=" bg-flat-color-1">
            <CardBody>
              <div className="stat-widget-one">
                  <div className="stat-icon dib"><i className={`ti-money text-success border-success`}></i></div>
                  <div className="stat-content dib">
                      <div className="stat-text">Valor Total</div>
                      <div className="stat-digit">{valorTotal}</div>
                  </div>
              </div>
            </CardBody>               
          </Card>
        </Col>
      </Row>
        <Row id="table-mensalidades">
          <Col>
              <DataTable
              columns={columns} 
              data={dados}
              responsive={true}
              striped={true}
              highlightOnHover={true}
              noDataComponent='Nenhum registro encontrado...'
              pagination/>
            </Col>
        </Row>              
      </form>  
      </div>
    )
  }