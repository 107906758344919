import React, { Component } from 'react';
import Aside from '../../components/Aside/Aside';
import RightPanel from '../../components/RightPanel/RightPanel';
import { BrowserRouter as Router} from "react-router-dom";

export default class DashBoard extends Component {
  render() {
    return (
        <div>
            <Router>
              <Aside/>            
              <RightPanel usuario={this.props.usuario} setarLogin={this.props.setarLogin}/>
            </Router>
        </div>        
    )
  }
}
