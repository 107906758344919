import React from 'react';
import './styles.css';
import {Route} from "react-router-dom";
import Home from '../../Pages/Home/Home';
import Recebimentos  from '../../Pages/Relatorios/Recebimentos/Recebimentos';
import Implantacoes  from '../../Pages/Relatorios/Implantacoes/Implantacoes';
import Mensalidades  from '../../Pages/Relatorios/Mensalidades/Mensalidades';
import Inadimplentes from '../../Pages/Relatorios/Inadimplentes/Inadimplentes';
const Content = () => (
    <div id="content" className="content mt-3">
        <Route exact path="/" component={Home}/>
        <Route path="/relatorios/recebimentos"  component={Recebimentos}/>
        <Route path="/relatorios/implantacoes"  component={Implantacoes}/>  
        <Route path="/relatorios/mensalidades"  component={Mensalidades}/>
        <Route path="/relatorios/inadimplentes" component={Inadimplentes}/>
    </div>  
);

export default(Content);