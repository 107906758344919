import React from 'react';
import './styles.css';
import Header from '../Header/Header';
import Content from '../Content/Content';

const RightPanel = ({usuario, setarLogin}) =>(
    <div id="right-panel" className="right-panel">
        <Header usuario={usuario} setarLogin={setarLogin}/>
        <Content />
    </div>
);

export default(RightPanel);