import  React, {useState, useEffect} from 'react';
import './styles.css';
import Cards from '../../components/Cards/Cards';
import Comparatives from '../../components/Comparatives/Comparatives';
import { Col, Row} from 'reactstrap';
import moment from 'moment'
import 'moment/locale/pt-br';
moment.locale('pt-br')

export default function Home(props){
    const [dataParametro, setDataParametro] = useState(new Date());  
    useEffect(() => {
        window.$('.datepicker').datepicker({
            format:      'MM yyyy',
            minViewMode: 1,
            maxViewMode: 2,
            language:    'pt-BR',
            autoclose:   true,            
        })
        .on('changeMonth',(e)=>{
         setDataParametro(e.date)          
        })
    }, [])
 
    return(
        <div id="Home" className="animated fadeIn content mt-3">
          <Row id="datepicker">
            <Col md="2">
              {/* <h6 id="texto-mes">Selecione o mês</h6>  */}
              <input type="text" defaultValue={moment().format('MMMM YYYY')} className="form-control datepicker" />  
            </Col>
          </Row>
            <Row id="cards">
              <Col>
                <Cards data={dataParametro}/> 
              </Col>
            </Row>
            <Row id="comparatives">
              <Col>
                <Comparatives data={dataParametro}/>
              </Col>        
            </Row>      
        </div>
    );    
}