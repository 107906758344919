import React, { Component }from 'react';
import                          './App.css';
//Components...
import Login     from './Pages/Login/Login';
import DashBoard from './Pages/DashBoard/DashBoard';
import Loading   from './components/Loading/Loading';
import Api       from './Api';
import Cookies   from 'js-cookie';

export default class App extends Component {
  state={
    logado: false,
    usuario: null,
    carregando: true,
    sair: false
  };
    login = (bool, usuario= null) =>{
      if (bool === false) {
       Cookies.remove('token')
      }
      this.setState({logado: bool, usuario})      
    }

    sair = (bool) =>{
      if (bool) {
        Cookies.remove('token')
        this.setState({logado: false})
      }
    }

    verificaLogin = () =>{
      const token = Cookies.get('token')
      if (token){
        Api.get(`/login/${token}`)
          .then( ({data}) =>{             
            if (data.logado) {
              this.setState({logado: true, carregando: false, usuario: data.usuario}) 
            } else{
              this.setState({carregando: false})
            }
        })        
      } else{
        this.setState({carregando: false})
      }      
    }

  componentDidMount(){
    this.verificaLogin()
  }

  render() {
    return (
      <div className='App'>
        {
          this.state.carregando
          ?
          <Loading></Loading>
          :          
          this.state.logado 
          ? 
            <DashBoard usuario={this.state.usuario} setarLogin={this.login}/>
          : 
            <Login setarLogin={this.login} />
        }
      </div>
    );
  }
}